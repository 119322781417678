import React, { useState, useCallback  } from "react";
import {
  Page,
  Card,
  FormLayout,
  Layout,
  Text,
  Tabs,
  TextField,
  Button,
} from "@shopify/polaris";
import ConnectWalletButton from "../../components/ConnectWalletButton";

const LoginPage: React.FC = () => {
  // const [email, setEmail] = useState<string>("");
  // const [password, setPassword] = useState<string>("");
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleEmailChange = (value: string) => setEmail(value);
  const handlePasswordChange = (value: string) => setPassword(value);

  // const handleEmailChange = (value: string) => setEmail(value);
  // const handlePasswordChange = (value: string) => setPassword(value);

  // const handleLogin = () => {
  //   // 在此处理登录逻辑
  //   console.log("Email:", email);
  //   console.log("Password:", password);
  // };

  const handleLogin = () => {
    // 处理账号密码登录逻辑
    console.log("Email:", email);
    console.log("Password:", password);
  };

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setActiveTab(selectedTabIndex);
  }, []);

  const tabs = [
    {
      id: "Connect",
      content: "Wallet",
      accessibilityLabel: "Basic",
      panelID: "basic-content",
    },
    {
      id: "Account",
      content: "Account Login",
      accessibilityLabel: "Basic",
      panelID: "basic-content",
    },
  ];

  return (
    <Page>
      <div className="login-page">
        <Layout sectioned>
          <Layout.Section>
            <div className="login-container">
              <Card
                padding={{
                  xs: "200",
                  sm: "500",
                  md: "800",
                  lg: "800",
                  xl: "600",
                }}
              >
                <FormLayout>
                  <div style={{ textAlign: "center" }}>
                    <Text variant="headingLg" as="h6">
                      欢迎登录
                    </Text>
                    {/* <TextStyle variation="subdued">请输入您的账号和密码</TextStyle> */}
                  </div>
                  <Tabs
                    tabs={tabs}
                    selected={activeTab}
                    onSelect={handleTabChange}
                  ></Tabs>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ConnectWalletButton
                      style={
                        activeTab === 0
                          ? { opacity: 1 }
                          : { opacity: 0, width: 0, height: 0 }
                      }
                    />
                  </div>
                  {activeTab === 1 && (
                    <div>
                      <TextField
                        label="邮箱"
                        value={email}
                        onChange={handleEmailChange}
                        type="email"
                        autoComplete="email"
                      />
                      <TextField
                        label="密码"
                        value={password}
                        onChange={handlePasswordChange}
                        type="password"
                        autoComplete="current-password"
                      />
                      <div style={{ marginTop: "20px" }}></div>
                      <Button variant="primary" onClick={handleLogin} fullWidth>
                        登录
                      </Button>
                    </div>
                  )}
                </FormLayout>
              </Card>
            </div>
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
};

export default LoginPage;
