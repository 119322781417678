import { combineReducers } from '@reduxjs/toolkit';

// 示例Reducer
const exampleReducer = (state = {}, action:any) => {
  switch (action.type) {
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  example: exampleReducer,
});

export default rootReducer;