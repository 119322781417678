import React, { useContext, useEffect } from "react";
import { AuthContext } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Button } from "@shopify/polaris";
interface IConnectWalletButtonProps {
  style?: React.CSSProperties;
}

const ConnectWalletButton: React.FC<IConnectWalletButtonProps> = ({
  style = {},
}: IConnectWalletButtonProps) => {
  const { user, wallet } = useContext(AuthContext);
  const navigate = useNavigate();

  const openModal = () => {
    wallet?.openModal();
  };

  useEffect(() => {
    if (user?.name) {
      console.log("test-user", user);
      navigate("/dashboard");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.name]);

  return (
    <div style={style}>

      <Button variant="primary" onClick={() => openModal()} fullWidth>
      Connect Wallet
      </Button>
    </div>
  );
};

export default ConnectWalletButton;
