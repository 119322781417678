import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { AppProvider } from '@shopify/polaris';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import '@shopify/polaris/build/esm/styles.css';
import { Buffer } from 'buffer';

// 将 Buffer 设置为全局变量
window.Buffer = Buffer;

const client = new ApolloClient({
  uri: 'http://localhost:4000/graphql',
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AppProvider i18n={{}}>
          <App />
        </AppProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);