import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Frame, Spinner } from "@shopify/polaris";
import Sidebar from "./components/Sidebar";
import "./App.css"; // 导入自定义CSS
import PrivateRoute from "./components/PrivateRoute";
import LoginPanel from "./pages/auth/Login";
import { AuthProvider } from "./hooks/useAuth";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Collections = React.lazy(() => import("./pages/collections/NftList"));
const CollectionList = React.lazy(
  () => import("./pages/collections/CollectionList")
);
const CollectionCreate = React.lazy(
  () => import("./pages/collections/CollectionCreate")
);

const AirdropCollectionCreate = React.lazy(
  () => import("./pages/collections/AirdropCollectionCreate")
);

const SingleNftCreate = React.lazy(
  () => import("./pages/collections/NftCreate")
);
const Transactions = React.lazy(() => import("./pages/Transactions"));
const Address = React.lazy(() => import("./pages/address/Address"));
const Activity = React.lazy(() => import("./pages/activity/Activity"));
const ActivityDetail = React.lazy(
  () => import("./pages/activity/ActivityDetail")
);
const AirdropNftItem = React.lazy(
  () => import("./pages/activity/deployer/AirdropNftItem")
);

const MemoizedSidebar = React.memo(Sidebar);

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <AuthProvider>
      <Frame>
        <div className={`App-layout ${isLoginPage ? "App-layout--full" : ""}`}>
          {!isLoginPage && (
            <div className="App-sidebar">
              <MemoizedSidebar />
            </div>
          )}
          <div className="App-content">
            <React.Suspense
              fallback={<Spinner accessibilityLabel="Loading" size="large" />}
            >
              {children}
            </React.Suspense>
          </div>
        </div>
      </Frame>
    </AuthProvider>
  );
};

const App: React.FC = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  // useEffect(() => {
  //   // 从 storage 中获取登录状态
  //   const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
  //   if (storedIsLoggedIn) {
  //     setIsLoggedIn(JSON.parse(storedIsLoggedIn));
  //   }
  // }, []);

  // 假设有一个处理登录逻辑的函数，更新 isLoggedIn 状态
  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  //   localStorage.setItem("isLoggedIn", JSON.stringify(true));
  // };

  // // 假设有一个处理注销逻辑的函数，清除 isLoggedIn 状态
  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  //   localStorage.removeItem("isLoggedIn");
  // };

  return (
    <Router>
      <AppLayout>
        <Routes>
          <Route path="/login" element={<LoginPanel />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/nfts" element={<Collections />} />
          <Route path="/nfts/series" element={<CollectionList />} />
          <Route path="/nfts/createCollection" element={<CollectionCreate />} />
          <Route
            path="/nfts/createAirdropCollection"
            element={<AirdropCollectionCreate />}
          />
          <Route path="/nfts/createSingle" element={<SingleNftCreate />} />
          <Route path="/nfts/create" element={<CollectionCreate />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/address" element={<Address />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/activity-detail" element={<ActivityDetail />} />
          <Route path="/nft-item-deployer" element={<AirdropNftItem />} />
        </Routes>
      </AppLayout>
    </Router>
  );
};

export default App;
