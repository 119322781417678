import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { TonConnectUI, ConnectedWallet } from "@tonconnect/ui";

interface User {
  // Define your user object structure here
  // Example:
  id: string;
  username: string;
  email: string;
  address: string;
  // Add more fields as per your actual user data structure
}

interface AuthContextType {
  user: ConnectedWallet | null;
  login: (data: User, fn?: () => void) => void;
  logout: () => void;
  wallet: TonConnectUI;
  setUser: (data: ConnectedWallet) => void;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [wallet, setWallet] = useState<TonConnectUI>(null as any);
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const login = async (data: User, fn?: () => void) => {
    setUser(data);
    fn?.();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = async () => {
    if (wallet?.connected) {
      await wallet?.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendTransaction = async (transaction: any) => {
    const res = await wallet?.sendTransaction(transaction);
    return res;
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      wallet,
      sendTransaction,
    }),
    [user, setUser, login, logout, wallet, sendTransaction]
  );

  useEffect(() => {
    const walletRef = new TonConnectUI({
      manifestUrl:
        "https://455631680.github.io/petrel-app/tonconnect-manifest.json",
      // buttonRootId: "connect-button-anchor",
    });

    walletRef?.onStatusChange((walletAndwalletInfo) => {
      if (walletRef.connected) {
        console.log("test-walletAndwalletInfo", walletAndwalletInfo);
        setUser(walletAndwalletInfo);
      } else {
        setUser(null);
        navigate("/login", { replace: true });
      }
    });
    setWallet(walletRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
