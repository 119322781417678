import {
  Frame,
  Navigation,
  Button,
  InlineStack,
  BlockStack,
  Text,
  RadioButton,
  Divider,
} from "@shopify/polaris";
import { HomeIcon, ProductIcon, ArrowLeftIcon } from "@shopify/polaris-icons";

import { AuthContext } from "../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState, useCallback } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

function Sidebar() {
  const location = useLocation();
  const { logout }: any = useContext(AuthContext);
  const navigate = useNavigate();

  const [config, setConfig] = useLocalStorage("config", null);
  const [net, setNet] = useState(config && config.net ? config.net : "testnet");

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleChange = useCallback((_: boolean, newValue: string) => {
    setNet(newValue);
    setConfig({
      ...config,
      net: newValue,
    });
  }, [config, setConfig]);

  return (
    <div className="Sidebar-container">
      <Frame>
        <Navigation location={location.pathname}>
          <Navigation.Section
            separator={true}
            items={[
              {
                url: "/dashboard",
                label: "Dashboard",
                selected: location.pathname === "/dashboard",
                icon: HomeIcon,
              },
              {
                url: "/nfts",
                label: "Nfts",
                icon: ProductIcon,
                selected: location.pathname === "/nfts",
                subNavigationItems: [
                  {
                    url: "/nfts/series",
                    excludePaths: ["#"],
                    disabled: false,
                    label: "Collections",
                    exactMatch: location.pathname === "/nfts/series",
                    matches: location.pathname === "/nfts/series",
                  },
                  {
                    url: "/nfts/Inventory",
                    excludePaths: ["#"],
                    disabled: false,
                    label: "Inventory",
                    matches: location.pathname === "/nfts/Inventory",
                    exactMatch: location.pathname === "/nfts/Inventory",
                  },
                ],
              },
              {
                url: "/transactions",
                label: "Transactions",
                selected: location.pathname === "/transactions",
                icon: ProductIcon,
              },
              {
                url: "/address",
                label: "Address",
                selected: location.pathname === "/address",
                icon: ProductIcon,
              },
              {
                url: "/activity",
                label: "Activity",
                selected: location.pathname === "/activity",
                icon: ProductIcon,
              },
            ]}
          />
        </Navigation>
      </Frame>
      <div className="Sidebar-footer">
        <InlineStack align="space-between" blockAlign="center">
          <Text variant="headingXs" as="span">
            网络
          </Text>
          <BlockStack>
            <RadioButton
              label="mainnet"
              checked={net === "mainnet"}
              id="mainnet"
              onChange={handleChange}
            />
            <RadioButton
              label="testnet"
              id="testnet"
              checked={net === "testnet"}
              onChange={handleChange}
            />
          </BlockStack>
        </InlineStack>
        <div style={{ marginTop: "20px" }}></div>
        <Divider borderColor="border" />
        <div style={{ marginTop: "20px" }}></div>
        <Button
          icon={ArrowLeftIcon}
          variant="secondary"
          onClick={() => handleLogout()}
          fullWidth
        >
          Logout
        </Button>
      </div>
    </div>
  );
}

export default Sidebar;
